.banner {
    background-color: bisque;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    padding: 20px; 
    
}

.banner-one {
    display: flex;
    flex-direction: row;
    width: 100%;
    position: relative;
}

.text-one {
    margin-top: 50px; 
}

.text-two {
    max-width: 600px; 
    word-wrap: break-word;
    line-height: 1.5;
    margin-top: 20px; 
    overflow-wrap: break-word;
}

.banner-image {
    position: relative;
    z-index: 1;
    margin-left: auto;
    margin-right: 20px; 
    max-width: 40%; 
    height: auto; 
}

.banner-image img {
    width: 100%; 
    max-height: 100%; 
    object-fit: contain; 
    display: block; 
}


@media (min-width: 1024px) {
    .banner {
        height: 250px; 
        padding: 20px; 
    }

    .banner-one {
        flex-direction: row; 
        height: 100%;
    }

    .banner-image {
        /* max-width: 40%;  */
        width: 100%;
    }

    .banner-text{
        width: 100%;
        margin-left: 150px;
    }

    .nav-logo{
        margin-left: 150px;
    }

    .navbar-items{
        margin-right: 100px;
    }
}

@media(min-width: 1400px){
    .banner {
        height: 250px; 
        padding: 20px; 
    }

    .banner-one {
        flex-direction: row; 
        height: 100%;
    }

    .banner-image {
        /* max-width: 40%;  */
        width: 100%;
    }

    .banner-text{
        width: 100%;
        margin-left: 300px;
    }
    .text-one{
        font-size: 2rem;
    }
    .text-two{
        font-size: 1.4rem;
        max-width: 500px !important; 
    }
    .nav-logo{
        margin-left: 200px;
    }
    .navbar-items{
        margin-right: 150px !important;
    }
}

@media(min-width: 1750px){
    .banner {
        height: 300px; 
        padding: 20px; 
    }

    .banner-one {
        flex-direction: row; 
        height: 100%;
    }

    .banner-image {
        /* max-width: 40%;  */
        width: 100%;
    }

    .banner-text{
        width: 100%;
        margin-left: 300px;
    }
    .text-one{
        font-size: 3rem;
    }
    .text-two{
        font-size: 2rem;
        max-width: 900px !important; 
    }
    .navbar-items{
        margin-right: 280px !important;
    }
    .nav-logo{
        margin-left: 300px;
    }
}

@media (min-width: 2200px){
    .banner {
        height: 300px; 
        padding: 20px; 
    }

    .banner-one {
        flex-direction: row; 
        height: 100%;
    }

    .banner-image {
        /* max-width: 40%;  */
        width: 100%;
        margin-right: 100px;
    }

    .banner-text{
        width: 100%;
        margin-left: 300px;
    }
    .text-one{
        font-size: 3rem;
    }
    .text-two{
        font-size: 2rem;
        max-width: 900px !important; 
    }
    .navbar-items{
        margin-right: 300px !important;
    }
}


@media (min-width: 768px) and (max-width: 1024px) {
    .banner {
        height: 220px; 
        padding: 15px; 
    }

    .banner-one {
        flex-direction: row; 
    }

    .banner-image {
        max-width: 35%; 
    }

    .text-one {
        margin-top: 40px; 
    }

    .text-two {
        margin-top: 15px; 
    }
}


@media (min-width: 576px) and (max-width: 768px) {
    .banner {
        height: auto; 
        padding: 10px; 
    }

    .banner-one {
        flex-direction: row; 
    }

    .text-one {
        margin-top: 30px; 
    }

    .text-two {
        margin-top: 10px; 
    }
}


@media (max-width: 576px) {
    .banner {
        height: auto; 
        padding: 0; 
    }

    .banner-one {
        flex-direction: column; 
        align-items: center; 
    }

    .banner-image {
        position: absolute; 
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        margin: 0; 
        max-width: none; 
    }

    .banner-image img {
        width: 100%; 
        height: 100%; 
        object-fit: cover; 
    }

    .banner-text {
        position: relative; 
        z-index: 2;
        padding: 20px; 
        background: rgba(255, 255, 255, 0.8); 
        border-radius: 8px; 
        width: 100%;
    }
    .text-one{
        margin-left: 10px;
    }
    .text-two{
        margin-left: 10px;
    }
}