html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex: 1;
  background-color: #dce0e2;
}

.sidebar {
  position: fixed;
  top: 0;
  right: -250px; /* Hidden off-screen to the right initially */
  height: 100%;
  width: 250px;
  background-color: brown;
  overflow-x: hidden;
  transition: 0.3s ease; /* Smooth slide effect */
  z-index: 1000;
  padding-top: 20px;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.sidebar ul li a {
  display: block;
  padding: 15px 20px;
  color: blanchedalmond;
  text-decoration: none;
}

.sidebar ul li a:hover {
  background-color: #a52a2a; 
}

.sidebar.active {
  left: 0; /* Slide in from the right when active */
}

.sidebar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  z-index: 999;
  display: block;
}

.nav-bar {
  height: 85px;
  background-color: brown;
  margin-top: 0;
  color: blanchedalmond;
  display: flex;
  justify-content: space-between; 
  align-items: center; 
  padding: 0 20px; 
  overflow: hidden;
}

.mobile-menu-icon {
  display: none; 
  font-size: 30px; 
  cursor: pointer; 
}

.nav-bar ul {
  list-style-type: none; 
  padding: 0; 
  margin: 0; 
  display: flex; 
  align-items: center; 
  height: 100%; 
}

.nav-bar .navbar-items{
  display: flex;
}

.nav-bar ul.active {
  display: block; 
}

.nav-bar li {
  position: relative; 
  margin-top: 0; 
  margin-right: 20px; /* Small difference between nav items */
}

.nav-bar a {
  text-decoration: none;
  color: inherit;
  padding: 30px; 
}

.nav-logo {
  margin-right: 40px; /* Increased space between logo and nav items */
  padding-top: 4px;
}

.nav-logo .logo {
  height: 70px; 
  width: auto; 
  object-fit: cover; 
  border-radius: 50%;
}



/* Centering nav items for screens larger than 922px */
@media (min-width: 922px) {
  .nav-bar .navbar-items {
    justify-content: center; /* Center nav items */
    
  }
}

/* Mobile styles for screens 922px or less */
@media (max-width: 922px) {
  .nav-bar ul {
    display: none; 
    flex-direction: column; 
    width: 100%; 
  }

  .nav-bar .navbar-items{
    display: none;
  }
  
  .nav-bar ul.active {
    display: flex; 
  }

  .mobile-menu-icon {
    display: block; 
  }
  
  .nav-bar {
    flex-direction: row; 
    height: auto; 
    padding: 10px; 
  }
  
  .nav-bar a {
    padding: 15px; 
  }
}
