.privacy {
    display: flex;
    flex-direction: column;
    justify-content: center; 
    align-items: center; 
    padding: 20px; 
    max-width: 800px; 
    /* width: 100%;  */
    margin: 0 auto;
}

.privacy-text-one,
.line-one {
    text-align: left; 
    width: 100%;
}

.privacy-text-two{
    text-align: left;
    width: 100%;
}

.privacy-text-three{
    text-align: left;
    width: 100%;
}