.about {
    display: flex; 
    justify-content: center; 
    padding: 20px; 
}
  
.about-text {
    width: 70%; 
    max-width: 800px; 
    text-align: left; 
}

.about-one{
    /* margin-left: 20px; */
    text-align: center;
    padding: 20px;
}

.about-image{
  width: 100%; /* or specific width */
  max-width: 500px; /* example width */
  height: auto;

  /* Center or position the image if necessary */
  display: block;
  margin: 0 auto;
}

.text-one{
    display: flex;
    flex-direction: column;
    
}

.text-two {
    max-width: 300px;        
    word-wrap: break-word;    
}

.banner-btn{
    color: #ffffff;
    background-color: #a52a2a;
    border-radius: 8%;
}