.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto; /* Allow scrolling if needed */
}

.modal-content {
  position: relative;
  max-width: 90%;
  max-height: 90%;
  display: flex; /* Ensure proper alignment */
  justify-content: center; /* Center image */
  align-items: center; /* Center image */
}

.modal-image {
  max-width: 100%;
  max-height: 80vh; /* Set max height to 80% of viewport height */
  width: auto; /* Maintain aspect ratio */
  height: auto; /* Maintain aspect ratio */
  border-radius: 10px;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 2rem;
  color: white;
  cursor: pointer;
}
