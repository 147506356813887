.gallery {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    max-width: 800px;
    /* width: 100%; */
    margin: 0 auto;
}

.photo-gallery {
    display: flex;
    gap: 10px; 
}

.column {
    display: flex;
    flex-direction: column;
}

.photo {
    margin-bottom: 10px; 
}

.photo img {
    width: 100%;
    height: auto;
    border-radius: 5px;
    object-fit: cover;
    cursor: pointer;
}

.loading-spinner-gallery {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    color: #555;
    height: 200px; /* Adjust based on the height you want for the image */
    width: 100%;
    background-color: #f0f0f0;
    text-align: center;
  }
  

@media (max-width: 800px){
    .photo-gallery{
        flex-direction: row;
    }
    .gallery {
        overflow-x: hidden;
        padding: inherit;
    }
    .pagination{
        margin-bottom: 20px;
    }
}

.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 15px;
}

.pagination button {
    margin: 0 5px;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    background-color: #a52a2a;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
}

.pagination button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.pagination span {
    align-self: center;
    margin: 0 10px;
}