.categories {
    width: 70%; 
    max-width: 800px; 
    margin: 0 auto; /* Center the container */
    text-align: left; 
    display: flex;
    flex-direction: column; 
    color: #a52a2a;
    margin-bottom: 1%;
    height: auto;
}

.catgory-img{
    width: 100%; 
  max-width: 200px; 
  height: auto;
}

.category {
  display: flex; 
  justify-content: center; 
  padding: 20px; 
}

.category-text {
  width: 70%; 
  max-width: 800px; 
  text-align: left; 
}