
.footer {
    background-color: #a52a2a;
    padding: 20px;
    font-size: 14px;
    color: #ffffff;
    text-align: center;
    flex-shrink: 0; 
}

.footer-content {
    margin-left: 20px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 20px;
}

.footer-column {
    flex: 1;
    min-width: 200px;
}


/* Adjust footer-description */
.footer-description {
    text-align: left;
    margin: 0; 
    padding: 10px; 
    line-height: 1.5; 
    margin-left: -35px;
}

/* Ensure the links have proper spacing */
.footer a {
    color: inherit;
    text-decoration: none;
    margin-top: 5px; 
    display: inline-block; 
}

.footer-subscribe {
    text-align: center; 
    margin: 20px 0; 
}

.subscribe-row {
    display: flex; 
    flex-direction: column;
    justify-content: center; 
    align-items: center; 
    margin: 10px 0; 
}

.subscribe-row input {
    width: 250px; 
    padding: 10px; 
    border: 1px solid #ccc; 
    border-radius: 4px; 
}

.subscribe-row button {
    margin-top: 10px;
    width: 270px; 
    padding: 10px; 
    background-color: #d97110; 
    color: white; 
    border: none; 
    border-radius: 4px; 
    cursor: pointer;
    /* margin-left: 10px;  */
}

.subscribe-row button:hover {
    background-color: #d97110; 
}

/* .footer-topics {
    text-align: left; 
} */



.footer-two {
    display: flex;
    flex-direction: row; 
    justify-content: space-between; 
    align-items: center; 
    padding: 10px; 
}

.copyright-text {
    margin-right: 20px; 
}

.footer-nav ul {
    list-style-type: none; 
    padding: 0; 
    margin: 0; 
    display: flex; 
}

.footer-nav li {
    margin-left: 15px; 
}

.footer-nav li a{
    text-decoration: none;
    color: #000000;
}


.footer-topics {
    text-align: center; 
    margin: 20px 0; 
}

.footer-socials {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around; 
    margin: 10px 0; 
    gap: 10px; 
    font-size: 22px;
}


.social-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px; 
    height: 60px; 
    border-radius: 50%; 
    border: 3px;
    border-style: dashed;
    color: white; 
    text-decoration: none; 
    transition: background-color 0.3s, transform 0.3s; 
    overflow: hidden; 
}


.social-icon i {
    font-size: 65px;
    line-height: 1; 
}

.social-icon:hover {
    background-color: #0c0b0b; 
    border: 3px dashed #00ccff; 
    transform: scale(1.1); 
}

@media (max-width: 600px) {
    .footer-two {
        flex-direction: column; 
        align-items: flex-start; 
        padding: 15px; 
    }

    .copyright-text {
        margin-right: 0; 
        margin-bottom: 10px; 
    }

    .footer-nav ul {
        flex-direction: column; 
        align-items: flex-start; 
        margin-top: 0; 
    }

    .footer-nav li {
        margin-left: 0; 
        margin-bottom: 5px; 
    }
}

@media (max-width: 699px){
    .footer-socials{
        font-size: larger;
    }
}

@media (max-width: 768px) {
    
    .footer-description {
        font-size: 12px; 
        padding: 5px; 
        margin-left: -35px;
    }

    
    .footer-subscribe {
        margin: 10px 0; 
    }

    .subscribe-row input {
        width: 85%; 
    }

    .subscribe-row button {
        width: 85%; 
    }
    
    
    .footer-socials {
        flex-direction: row; 
        justify-content: center; 
        gap: 8px;
    }

    .social-icon {
        width: 40px;
        height: 40px;
    }

    .social-icon i {
        font-size: 24px; 
    }
}


@media (max-width: 479px){
    .footer-description{
        margin-left: 0px;
        text-align: center;
    }
}

@media (min-width: 600px) {
    
    .footer-content {
        flex-direction: row; 
    }

    .footer-column {
        max-width: none; 
    }
}

@media (min-width: 1024px) {
    .footer-description {
        margin-left: 120px;
    }
    .footer-topics{
        margin-right: 100px;
    }
    .copyright-text{
        margin-left: 150px;
    }
    .footer-nav{
        margin-right: 100px;
    }
}

@media (min-width: 1400px) {
    .footer-description {
        margin-left: 200px;
    }
    .footer-topics{
        margin-right: 250px;
    }
    .copyright-text{
        margin-left: 250px;
    }
    .footer-nav{
        margin-right: 250px;
    }
}

@media (min-width: 1750px){
    .copyright-text{
        margin-left: 293px;
    }
    .footer-topics{
        margin-right: 300px;
    }
    .footer-description {
        margin-left: 260px;
    }
    .footer-nav{
        margin-right: 320px;
    }
}

