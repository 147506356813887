.disclaimer-container{
    display: flex;
    flex-direction: column;
    justify-content: center; 
    align-items: center; 
    padding: 20px; 
    max-width: 800px; 
    /* width: 100%;  */
    margin: 0 auto;
}

.disclaimer-text-one{
    text-align: left; 
    width: 100%;
}

.disclaimer-text-two{
    text-align: left; 
    width: 100%;
    font-weight: bold;
}

