.conditions{
    display: flex;
    flex-direction: column;
    justify-content: center; 
    align-items: center; 
    padding: 20px; 
    max-width: 800px; 
    /* width: 100%;  */
    margin: 0 auto;
}

.conditions-text-one{
    text-align: left; 
    width: 100%;
}

.conditions-text-two{
    text-align: left;
    width: 100%;
}

.conditions-text-three{
    text-align: left;
    width: 100%;
}

.conditions-text-four{
    text-align: left;
    width: 100%;
}