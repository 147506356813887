/* Main container for YouTube videos */
.youtube-video {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style-type: none;
  padding: 0;
  margin: 0 auto;
}

.youtube-video-extra{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style-type: none;
  padding: 0;
  margin: 0 auto;
}

.youtube-video li {
  display: flex;
  flex-wrap: wrap;
  align-items: end;
  margin: 25px;
  box-sizing: border-box;
}

.youtube-video-extra li{
  display: flex;
  flex-wrap: wrap;
  align-items: end;
  margin: 25px;
  box-sizing: border-box;
}

/* Heading styling for video titles */
.youtube-video h4 {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  margin: 10px 0;
  max-width: 100%;
  word-wrap: break-word; /* Ensure long titles wrap properly */
  text-align: left;
}

.youtube-video-extra h4{
  font-size: 16px;
  font-weight: bold;
  color: #333;
  margin: 10px 0;
  max-width: 100%;
  word-wrap: break-word; /* Ensure long titles wrap properly */
  text-align: left;
}

/* Video thumbnail adjustments */
.youtube-video iframe {
  width: 100%;
  /* height: auto; */
  aspect-ratio: 16/9; /* Maintain 16:9 aspect ratio */
  border-radius: 8px;
  margin-bottom: 10px;
}

.youtube-video-extra iframe{
  width: 100%;
  height: auto;
  aspect-ratio: 16/9; /* Maintain 16:9 aspect ratio */
  border-radius: 8px;
  margin-bottom: 10px;
}

/* Long videos */
.long-video {
  flex: 0 1 calc(33.333% - 20px); /* Default for larger screens */
  margin: 0 auto;
  display: flex; /* Allow flex properties for children */
  flex-direction: column; /* Stack children vertically */
  align-items: center; /* Center children horizontally */
}

/* Short videos (YouTube shorts) */
.shorts-video {
  width: 170px;
}

/* Centering the toggle buttons */
.video-toggle-buttons {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.video-toggle-buttons button {
  background-color: #cccccc;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 0 10px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
  font-family: 'Times New Roman', Times, serif;
}

.video-toggle-buttons button:hover {
  background-color: #a52a2a;
}

.video-toggle-buttons button.active {
  background-color: #a52a2a;
}

/* For aligning shorts and long videos */
.youtube-shorts, .youtube-long-videos {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Spinner for loading */
.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 315px;
}

.loading-spinner {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #3498db;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Responsive adjustments for mobile screens */
@media (max-width: 499px) {
  .youtube-shorts, .youtube-long-videos {
    flex-direction: column;
    align-items: center;
  }

  .youtube-video {
    justify-content: center;
  }

  .youtube-video-extra{
    justify-content: center;
  }

  .long-video {
    flex: 1 1 100%; /* Full width for long videos on small screens */
  }
}

/* Adjustments for screens wider than 750px */
@media (max-width: 750px) {
  .long-video {
    flex: 1 1 100%; /* Make long videos take full width */
  }
}

/* Adjustments for medium screens (min-width 550px) */
@media(min-width: 550px) {
  .youtube-shorts, .youtube-long-videos {
    flex-direction: column;
    align-items: center;
  }

  .youtube-video {
    justify-content: center;
  }

  .youtube-video-extra{
    justify-content: center;
  }
}

/* Larger screen adjustments */
@media(min-width: 1024px) {
  .youtube-shorts, .youtube-long-videos {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .youtube-video {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 900px;
    margin: 0 auto;
  }

  .youtube-video-extra{
    justify-content: center;
  }

  .shorts-video {
    width: 170px;
    align-items: end;
  }
}

/* For screens wider than 1250px */
@media(min-width: 1250px) {
  .youtube-shorts, .youtube-long-videos {
    flex-direction: column;
    align-items: center;
  }

  .youtube-video {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 980px;
    margin: 0 auto;
  }

  .shorts-video {
    width: 170px;
    align-items: end !important;
  }

  .youtube-video-extra{
    justify-content: center;
    max-width: 1400px;
  }
}

/* For screens wider than 1500px */
@media (min-width: 1500px) {
  .youtube-shorts, .youtube-long-videos {
    flex-direction: column;
    align-items: center;
  }

  .youtube-video {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 1000px;
    margin: 0 auto;
  }

  .shorts-video {
    width: 170px;
    align-items: end !important;
  }

  .youtube-video-extra{
    justify-content: center;
    max-width: 1400px;
  }
}

/* For screens wider than 1750px */
@media (min-width: 1750px) {
  .youtube-shorts {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .youtube-video {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 1000px;
    margin: 0 auto;
  }

  .shorts-video {
    width: 170px;
    align-items: end !important;
  }

  .youtube-video-extra{
    justify-content: center;
    max-width: 1400px;
  }
}
