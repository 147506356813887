.contact {
    display: flex;
    flex-direction: column; 
    align-items: center;    
    text-align: center;      
    padding: 20px;         
    height: 500px;
}

.contact-us {
    margin-bottom: 10px; 
}

.contact-text p {
    max-width: 600px; 
}

.contact-image{
    text-align: center;
    padding: 20px;
    max-width: 100%; /* Ensures the container doesn't overflow */
    overflow: hidden;
}

.contact-img{
  width: 100%; 
  max-width: 500px; 
  height: auto;
  border-radius: 5px;
  display: block;
  margin: 0 auto;
  height: 100%;
}